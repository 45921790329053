import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { createPatient } from 'api/alpha/patients';
import { getUsers } from 'api/users';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import PatientForm from './patientForm';

const AVPatientCreatePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const wardId = searchParams.get('ward_id');
  const [userList, setUserList] = useState([]);

  const createPatientToApi = useCallback((values, { setFieldError }) => {
    const payload = {};

    if (wardId) {
      payload.ward = wardId;
      payload.name = values.name;
      payload.patient_identifier = values.patient_identifier;
      payload.patient_info = values.patient_info;
      payload.family_username = values.family_username;
      payload.phone = values.phone;
      payload.address = values.address;
      payload.radar_id = values.radar_id;
    }

    return createPatient(payload)
      .then((response) => {
        if (response.status === 201) {
          navigate(-1);
        }
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [navigate, wardId]);

  const fetchUsers = useCallback(() => {
    return getUsers()
      .then(({ data }) => {
        const familyMemberUsers = [];
        data.forEach((user) => {
          if (user.role === 3 && user.patients?.length === 0) {
            familyMemberUsers.push(
              {
                value: user.username,
                label: `${user.display_name} - ${user.username}`,
              },
            );
          }
        });
        setUserList(familyMemberUsers);
      });
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <MKBox flex={1} py="2rem">
      <AVBreadcrumb startingNode="Create Patient" showBackButton />
      <PatientForm
        patient={{ ward_id: wardId }}
        users={userList}
        onSave={createPatientToApi}
      />
    </MKBox>
  );
};

export default AVPatientCreatePage;
