import api from 'api';

export const getAlerts = (params) => {
  return api.get('/alerts', params, 'bearer');
};

export const getAlert = (id, params) => {
  return api.get(`/alerts/${id}`, params, 'bearer');
};

export const updateAlert = (id, body) => {
  return api.put(`/alerts/${id}`, body, 'bearer');
};

export const createAlert = (body) => {
  return api.post('/alerts', body, 'bearer');
};

export const deleteAlert = (id) => {
  return api.delete(`/alerts/${id}`, 'bearer');
};

export const pushAlert = (username, body) => {
  return api.post(`/central/send_push_notification/${username}`, body, 'bearer');
};
