import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { useAuth } from 'contexts/auth';
import Select from 'components/Select';
import { useNavigate } from 'react-router-dom';
import { getLocaleMap } from 'utils/locales';
import ConfirmDialog from '../AVWardPatientDetails/dialog';

const ReportForm = ({ info, initialValues, onSubmit, onSave, ...props }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['false_alarm', 'true_alarm', 'save', 'submit', 'back'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const { auth } = useAuth();
  const navigate = useNavigate();
  const user = auth?.user;

  const [open, setOpen] = useState(false);
  const [formikValues, setFormikValues] = useState({});
  const onDecline = () => {
    setFormikValues({});
    setOpen(false);
  };

  const handleOpenConfirmDialog = (values) => {
    setFormikValues(values);
    setOpen(true);
  };

  const onAccept = (value) => {
    setOpen(false);
    if (value) {
      onSubmit(formikValues);
    }
  };

  const initValues = useMemo(() => ({
    ward_id: initialValues?.ward_id || info?.ward_id || '',
    center_id: initialValues?.center_id?.center_id || info?.center_id.center_id || '',
    ward_name: initialValues?.ward_name || info?.name || '',
    center_name: initialValues?.center_name || info?.center_id?.name || '',
    service_provider_id: initialValues?.service_provider_id || info?.center_id?.service_provider_id.service_provider_id || '',
    service_provider_name: initialValues?.service_provider_name || info?.center_id?.service_provider_id?.name || '',
    accident_time: initialValues?.accident_time || info?.accident?.accident_time || '',
    guest_id: initialValues?.guest_id || info?.accident?.patient?.patient_identifier || '',
    device_physical_id: initialValues?.device_physical_id || info?.accident?.device?.device_physical_id || '',
    guest_info: initialValues?.guest_info || info?.accident?.patient?.patient_info || '',
    details: initialValues?.details || info?.details || '',
    reported_by: initialValues?.reported_by || user?.username || '',
    tags: initialValues?.tags || info?.accident?.tag || '',
    false_alarm: initialValues?.false_alarm || info?.false_alarm || false,
    alert_id: initialValues?.alert_id || info?.accident?.alert_id || '',
    camera_id: initialValues?.camera_id || info?.accident?.camera?.camera_identifier || '',
  }), [info?.accident?.accident_time, info?.accident?.alert_id, info?.accident?.camera?.camera_identifier, info?.accident?.device?.device_physical_id, info?.accident?.patient?.patient_identifier, info?.accident?.patient?.patient_info, info?.accident?.tag, info?.center_id.center_id, info?.center_id?.name, info?.center_id?.service_provider_id?.name, info?.center_id?.service_provider_id.service_provider_id, info?.details, info?.false_alarm, info?.name, info?.ward_id, initialValues?.accident_time, initialValues?.alert_id, initialValues?.camera_id, initialValues?.center_id?.center_id, initialValues?.center_name, initialValues?.details, initialValues?.device_physical_id, initialValues?.false_alarm, initialValues?.guest_id, initialValues?.guest_info, initialValues?.reported_by, initialValues?.service_provider_id, initialValues?.service_provider_name, initialValues?.tags, initialValues?.ward_id, initialValues?.ward_name, user?.username]);
  return (
    <Formik
      onSubmit={handleOpenConfirmDialog}
      initialValues={initValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container spacing={1} justifyContent="right">
              <Grid container item xs={12}>
                <Select
                  name="false_alarm"
                  value={values.false_alarm}
                  onChange={(v) => { setFieldValue('false_alarm', v); }}
                  options={[{ label: localeMapping.true_alarm, value: true }, { label: localeMapping.false_alarm, value: false }]}
                  fullWidth
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="details"
                  value={values.details || ''}
                  onChange={handleChange('details')}
                  onBlur={handleBlur('details')}
                  multiline
                  minRows={5}
                  maxRows={15}
                  fullWidth
                />
              </Grid>
              <Grid container xs={12} mt={1}>
                <Grid xs={6}>
                  <Button
                    type="button"
                    variant="gradient"
                    color="info"
                    onClick={() => navigate(-1)}
                    sx={{ width: '20%' }}
                  >
                    {localeMapping.back}
                  </Button>
                </Grid>
                <Grid container xs={6} flexDirection="row" justifyContent="flex-end">
                  <Button
                    type="button"
                    variant="gradient"
                    color="info"
                    disabled={!dirty}
                    onClick={() => onSave(values)}
                    sx={{ width: '30%', marginRight: 2 }}
                  >
                    {localeMapping.save}
                  </Button>
                  <Button
                    type="button"
                    variant="gradient"
                    color="info"
                    sx={{ width: '30%' }}
                    onClick={() => handleOpenConfirmDialog(values)}
                  >
                    {localeMapping.submit}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
              </Grid>
            </Grid>
            <ConfirmDialog open={open} onAccept={onAccept} onDecline={onDecline} />

          </MKBox>
        );
      }}
    </Formik>
  );
};

ReportForm.propTypes = {
  info: PropTypes.shape({
    ward_id: PropTypes.string,
    center_id: PropTypes.string,
    name: PropTypes.string,
    false_alarm: PropTypes.bool,
    details: PropTypes.string,
    accident: PropTypes.shape({
      alert_id: PropTypes.string,
      tags: PropTypes.string,
      tag: PropTypes.string,
      accident_time: PropTypes.string,
      patient: PropTypes.shape({
        patient_identifier: PropTypes.string,
        patient_info: PropTypes.string,
      }),
      device: PropTypes.shape({
        device_physical_id: PropTypes.string,
      }),
      camera: PropTypes.shape({
        camera_identifier: PropTypes.string,
      }),
    }),
  }),
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onSubmit: PropTypes.func,
};

ReportForm.defaultProps = {
  info: {},
  onSave: () => {},
  onSubmit: () => {},
};

export default ReportForm;
