import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { getDynamicData } from 'utils/sections';
import { getDynamicTableRows } from 'api/sections';
import { getCollectionDefinition } from 'api/collection_definitions';
import DataTable from 'components/DataTable';
import MKBox from 'components/MaterialKit/MKBox';
import { datetimeFormatter } from 'utils/datetime';
import MKTypography from 'components/MaterialKit/MKTypography';
import PropTypes from 'prop-types';
import { getLocaleMap } from 'utils/locales';

const AVGuestRecordTable = ({ guest_id }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['guest_record'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const [reports, setReports] = useState([]);

  const wardIssueReportTableColumns = useMemo(() => [
    { field: 'details', label: 'Details' },
    { field: 'accident_tags', label: 'Alert Type' },
    { field: 'device_number', label: 'Device Number' },
    { field: 'guest_id', label: 'Guest ID' },
    { field: 'guest_info', label: 'Guest Info' },
    { field: 'accident_time', label: 'Accident Time' },
    { field: 'createddate', label: 'Submitted at' },
    { field: 'reported_by', label: 'Person Incharge' },
  ], []);

  const fetchReportsFromApi = useCallback((attributes) => {
    getDynamicTableRows('3ffd1633-4903-40f5-8520-f898e57d6c3b')
      .then(({ data }) => {
        const tableRows = data.map((row) => {
          const dynObj = getDynamicData(row, attributes);
          dynObj.createddate = dynObj.createddate ? 'N/A' : row.createddate;
          if (dynObj.tags) {
            const tags = JSON.parse(JSON.parse(dynObj.tags));
            dynObj.accident_tags = tags.pop();
          }
          return dynObj;
        });
        setReports(tableRows);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('err', err);
      });
  }, []);

  const fetchCollectionDefinition = useCallback(() => {
    return getCollectionDefinition('3ffd1633-4903-40f5-8520-f898e57d6c3b', { $expand: 'attributes' })
      .then(({ data }) => {
        return data.attributes;
      });
  }, []);

  useEffect(() => {
    fetchCollectionDefinition()
      .then((attributes) => fetchReportsFromApi(attributes));
  }, [fetchCollectionDefinition, fetchReportsFromApi]);

  const rowData = useMemo(() => {
    // TODO - Implement the logic to map the data to the columns / Filter by WARD ID got from location.state.info.ward_id

    if (!reports) return [];
    return reports.map((row) => {
      const bod = {
        ...row,
      };
      bod.createddate = datetimeFormatter(row.createddate, 'YYYY-MM-DD HH:mm:ss');
      bod.accident_time = datetimeFormatter(Number(row.accident_time), 'YYYY-MM-DD HH:mm:ss');
      return bod;
    }).filter((row) => row.guest_id === guest_id);
  }, [reports, guest_id]);

  return (
    <MKBox my={2}>
      <MKTypography variant="body1" fontSize="16px" fontWeight="bold" color="#3583C5">
        {localeMapping.guest_record}
      </MKTypography>
      <DataTable
        data={rowData}
        columns={wardIssueReportTableColumns}
        idField="guest_id"
      />
    </MKBox>
  );
};

AVGuestRecordTable.propTypes = {
  guest_id: PropTypes.string.isRequired,
};

export default AVGuestRecordTable;
