import React, { useEffect, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { getLocaleMap } from 'utils/locales';
import LogDisplayerDialog from './logDisplayerDialog';
import { generateContrastingColor } from './helperFunctions';

const WardCard = ({ ward }) => {
  const bgColor = '#3fafaa';
  // const bgColor = generateContrastingColor(ward.center_id);
  const navigate = useNavigate();
  const [logsOpen, setLogsOpen] = useState(false);
  const [localeMapping, setLocaleMapping] = useState({});
  const thirtyWardCenterId = '4d3d02d2-c80f-475c-aa6b-a652b66420db';

  useEffect(() => {
    getLocaleMap(['details', 'show_logs', 'guests', 'alerts', 'emergency_alarm', 'falls'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const handleOpenLogModal = () => {
    setLogsOpen(true);
  };

  const handleCloseLogModal = () => {
    setLogsOpen(false);
  };

  return (
    <MKBox sx={{ border: '1.5px solid', backgroundColor: 'gray', borderRadius: '8px' }}>
      <MKBox px={2} py={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ borderRadius: '8px 8px 0px 0px', backgroundColor: bgColor }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={7}>
            <MKBox color="#fff" display="flex" flexDirection="column" alignItems="left">
              <MKTypography color="inherit" fontWeight="bold">{ward.ward_name}</MKTypography>
              <MKTypography color="inherit" fontWeight="bold">{ward.center_name}</MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={5}>
            <Button
              variant="contained"
              size="small"
              color="#3583C5"
              fontSize="12px"
              fullWidth
              onClick={() => { navigate(`/users/ward-details?ward_id=${ward.ward_id}`); }}
              endIcon={(
                <ChevronRightIcon fontSize="inherit" />
            )}
            >
              <MKTypography variant="body1" color="white" fontWeight="bold" fontSize="inherit">{localeMapping.details}</MKTypography>
            </Button>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox px={2} py={1} display="flex" flexDirection="column" alignItems="left" sx={{ borderRadius: '0px 0px 8px 8px', backgroundColor: '#EEFDFF' }}>
        <MKTypography variant="body1" fontSize="16px">{`${localeMapping.guests}: ${ward?.guests || 0}`}</MKTypography>
        <MKTypography variant="body1" fontSize="16px">{`${thirtyWardCenterId === ward.center_id ? localeMapping.falls : localeMapping.alerts}: ${ward?.alerts || 0}`}</MKTypography>
        <Button
          variant="contained"
          size="small"
          color="#3583C5"
          fontSize="14px"
          onClick={() => handleOpenLogModal()}
          sx={{ mt: 1 }}
        >
          {localeMapping.show_logs}
        </Button>
      </MKBox>
      <LogDisplayerDialog
        open={logsOpen}
        ward={ward}
        onClose={handleCloseLogModal}
      />
    </MKBox>
  );
};

WardCard.propTypes = {
  ward: PropTypes.object.isRequired,
};

export default WardCard;
