import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useGlobalData } from 'contexts/global-data';
import { useAuth } from 'contexts/auth';
import { getStoredAuth } from 'api';
import { getObjectTypes, getPublicObjectTypes } from 'api/object_types';
import { getMe } from 'api/users';
import { useWards } from 'contexts/wards';
import { useSignals } from 'contexts/signals';
import theme from 'assets/theme';
import routes from 'routes';
import getStompClient from 'socket/stompClient';
import { getAlerts } from 'api/alpha/alerts';
import alertSound from 'assets/sounds/alert-4s.mp3';
import './i18n';

const AuthRedirectior = ({ required, to, children }) => {
  const location = useLocation();
  const { auth } = useAuth();
  if (required && Object.prototype.hasOwnProperty.call(required, 'auth')) {
    if (required.auth) {
      if (!auth.user) {
        return <Navigate to={`${to}?redirect=${location.pathname}${location.search}`} />;
      }
    } else if (auth.user) {
      return <Navigate to="/" />;
    }
  }
  return children;
};

AuthRedirectior.propTypes = {
  required: PropTypes.shape({
    auth: PropTypes.bool,
  }),
  to: PropTypes.string,
  children: PropTypes.node,
};

const App = () => {
  const { pathname } = useLocation();
  const { auth, setAuth, setAuthUser } = useAuth();
  const { signal, setSignal } = useSignals();
  const { wards, setWards, updateWardAlerts, nullWardAlerts, getNotifications, notifications, addNotification, deleteNotification } = useWards();
  // const { notifications, addNotification, deleteNotification } = useNotifications();
  const { setObjectTypes } = useGlobalData();

  const fetchObjectTypesFromApi = useCallback(() => {
    const objectTypeParams = {
      $orderBy: 'object_type_id',
    };
    return (auth.user ? getObjectTypes(objectTypeParams) : getPublicObjectTypes(objectTypeParams))
      .then(({ data }) => {
        setObjectTypes(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [auth.user, setObjectTypes, setAuth]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const storedAuth = getStoredAuth();
    if (get(storedAuth, 'access_token') && get(storedAuth, 'user')) {
      setAuth(storedAuth);
      getMe({ $expand: 'role/scopes' })
        .then(({ data: user }) => {
          const updatedAuth = { ...getStoredAuth(), user };
          setAuth(updatedAuth);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [setAuth, setAuthUser]);

  useEffect(() => {
    fetchObjectTypesFromApi();
  }, [fetchObjectTypesFromApi]);

  const getRoutes = useMemo(() => (allRoutes) => {
    return allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            key={route.name}
            path={route.route}
            element={(
              <AuthRedirectior required={route.required} to="/login">
                {route.component}
              </AuthRedirectior>
            )}
          />
        );
      }

      return null;
    });
  }, []);

  const [prevWards, setPrevWards] = useState(null);

  const fetchAlerts = useCallback(() => {
    if (wards) {
      return getAlerts({
        '$expand': 'ward/center_id/service_provider_id,patient/family_username,device,camera',
        'status[null]': true,
      })
        .then(({ data }) => {
          const unresolvedAlerts = data.filter((alert) => alert.status === null);
          const map = {};
          unresolvedAlerts.sort((a, b) => new Date(b.accident_time) - new Date(a.accident_time));
          unresolvedAlerts.forEach((alert) => {
            if (!map[alert.ward.ward_id]) {
              map[alert.ward.ward_id] = [];
              map[alert.ward.ward_id].push(alert);
            } else {
              map[alert.ward.ward_id].push(alert);
            }
          });
          setPrevWards(updateWardAlerts(map));
          return data;
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [setAuth, updateWardAlerts, wards]);

  useEffect(() => {
    if (wards && !prevWards) {
      setPrevWards(wards);
      fetchAlerts();
    } else if (wards && prevWards) {
      if (wards !== prevWards) {
        fetchAlerts();
      }
    }
  }, [prevWards, wards, fetchAlerts]);

  const updateWardsBasedOnAlertsTable = useCallback(() => {
    if (wards) {
      const audio = new Audio(alertSound);
      audio.play().catch((error) => {
        console.log('Failed to play audio:', error);
      });
      fetchAlerts();
    }
  }, [fetchAlerts, wards]);

  const [stompClient, setStompClient] = useState(null);
  const addFallAlertListener = useCallback((client) => {
    if (client && client.connected) {
      client.subscribe('/fall_detection', (res) => {
        console.log('update ward')
        updateWardsBasedOnAlertsTable();
      });
    }
  }, [updateWardsBasedOnAlertsTable]);

  useEffect(() => {
    if (stompClient) {
      addFallAlertListener(stompClient);
    }
  }, [addFallAlertListener, stompClient]);

  const connectWebsocket = useCallback(() => {
    if (!stompClient) {
      const client = getStompClient();
      setStompClient(client);
      client.onConnect = (frame) => {
        if (client.connected) {
          console.log('connected out websocket');
          addFallAlertListener(client);
          updateWardsBasedOnAlertsTable();
        }
      };
      client.onStompError = (err) => {
        console.error('stompClient err', err);
      };
      client.activate();
    }
  }, [addFallAlertListener, stompClient, updateWardsBasedOnAlertsTable]);

  useEffect(() => {
    if (auth.user) {
      connectWebsocket();
    }
  }, [auth.user, connectWebsocket]);

  useEffect(() => {
    if (signal) {
      setTimeout(() => {
        updateWardsBasedOnAlertsTable();
        setSignal(false);
      }, 1000);
    }
  }, [setSignal, signal, updateWardsBasedOnAlertsTable]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MKBox display="flex" flexDirection="column" height={1}>
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </MKBox>
    </ThemeProvider>
  );
};

export default App;
