import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { getPatient, updatePatient } from 'api/alpha/patients';
import { getUsers } from 'api/users';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';
import PatientForm from './patientForm';

const AVPatientEditPage = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['edit'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const patientId = searchParams.get('patient_id');
  const [userList, setUserList] = useState([]);
  const [patient, setPatient] = useState(null);

  const fetchUsers = useCallback(() => {
    return getUsers()
      .then(({ data }) => {
        const familyMemberUsers = [];
        data.forEach((user) => {
          if (user.role === 3 && (user.patients?.length === 0 || user.patients?.includes(patientId))) {
            familyMemberUsers.push(
              {
                value: user.username,
                label: `${user.display_name} - ${user.username}`,
              },
            );
          }
        });
        setUserList(familyMemberUsers);
      });
  }, [patientId]);

  const updatePatientToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      name: values.name,
      patient_identifier: values.patient_identifier,
      patient_info: values.patient_info,
      phone: values.phone,
      address: values.address,
      family_username: values.family_username,
      radar_id: values.radar_id,
    };
    return updatePatient(patientId, payload)
      .then((response) => {
        if (response.status === 200) {
          navigate(-1);
        }
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [navigate, patientId]);

  const fetchPatientFromApi = useCallback(() => {
    if (!patientId) return Promise.resolve();
    return getPatient(patientId)
      .then(({ data }) => {
        setPatient(data);
      });
  }, [patientId]);

  useEffect(() => {
    fetchUsers();
    fetchPatientFromApi();
  }, [fetchPatientFromApi, fetchUsers]);

  const initialValues = useMemo(
    () => {
      return {
        name: patient?.name || '',
        patient_identifier: patient?.patient_identifier || '',
        patient_info: patient?.patient_info || '',
        family_username: patient?.family_username || '',
        phone: patient?.phone || '',
        address: patient?.address || '',
        radar_id: patient?.radar_id || '',
      };
    },
    [patient],
  );

  if (!patient) {
    return null;
  }

  return (
    <MKBox flex={1} py="2rem">
      <AVBreadcrumb startingNode={localeMapping.edit} items={[patient?.name]} showBackButton />
      <PatientForm
        patient={initialValues}
        users={userList}
        onSave={updatePatientToApi}
      />
    </MKBox>
  );
};

export default AVPatientEditPage;
