import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { getPatient } from 'api/alpha/patients';
import Button from 'components/Button';
import { Grid } from '@mui/material';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';
import { useAuth } from 'contexts/auth';
// eslint-disable-next-line import/no-cycle
import AVPatientRecordsTable from './patientRecordsTable';

const WardPatientDetailsSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['guest_name', 'family_member_name', 'center', 'ward_location', 'contact_number', 'guest_address', 'guest_phone_number', 'family_member_contact_detail', 'edit', 'radar_id'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const patientId = searchParams.get('patient_id');
  const [patient, setPatient] = useState(null);
  const [wardData, setWardData] = useState({});

  const { auth } = useAuth();
  const userRoleScopes = useMemo(() => auth.user?.role?.scopes || [], [auth.user]);
  const isAdmin = !!userRoleScopes.find((roleScope) => roleScope?.scope === 'admin_only');

  const fetchPatientFromApi = useCallback(() => {
    if (!patientId) {
      return Promise.resolve();
    }
    return getPatient(patientId, {
      $expand: 'ward/center_id/service_provider_id,family_username',
    })
      .then(({ data }) => {
        setPatient(data);
        setWardData(data.ward);
      });
  }, [patientId]);

  useEffect(() => {
    fetchPatientFromApi();
  }, [fetchPatientFromApi]);

  if (!patient) {
    return null;
  }

  return (
    <MKBox>
      <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" py="1rem">
        <AVBreadcrumb startingNode={wardData.name} items={[patient.name]} showBackButton />
        {isAdmin && <Button variant="contained" size="medium" color="info" onClick={() => { navigate(`providers/patients/ward_overview/edit?patient_id=${patient.patient_id}`); }}>{localeMapping.edit}</Button>}
      </MKBox>
      <Grid container item xs={6} spacing={0}>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${localeMapping.guest_name}:`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {patient?.name}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${localeMapping.family_member_name}:`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {patient?.family_username?.display_name}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${localeMapping.center} (${localeMapping.ward_location}):`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${wardData?.center_id?.service_provider_id?.name} /${wardData?.center_id?.name} /${wardData?.name}`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${localeMapping.contact_number}:`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {patient?.family_username?.phone}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${localeMapping.guest_address}:`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {patient?.address}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${localeMapping.guest_phone_number}:`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {patient?.phone}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${localeMapping.family_member_contact_detail}:`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {patient?.family_username?.username}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${localeMapping.radar_id}:`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {patient?.radar_id}
          </MKTypography>
        </Grid>
      </Grid>
      <AVPatientRecordsTable
        guest_id={patient.patient_identifier}
        patient_id={patientId}
        ward_id={wardData.ward_id}
      />
    </MKBox>
  );
};

export default WardPatientDetailsSection;
