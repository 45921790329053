import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { getWards, updateWard } from 'api/alpha/wards';
import alertSound from 'assets/sounds/alert-4s.mp3';
import { getUserServiceProviders } from 'api/alpha/service_providers';
import { useAuth } from './auth';

const WardsContext = React.createContext();
const { Provider, Consumer: WardsConsumer } = WardsContext;

export const WardsProvider = ({ children }) => {
  const [wards, setWards] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const { auth, setAuth } = useAuth();

  const updateWardAlerts = (wardAlertMap) => {
    const wardIds = Object.keys(wardAlertMap);
    const newWardAlerts = (wards || []).map((ward) => {
      const alertWardId = wardIds.find((wardId) => wardId === ward.ward_id);
      if (alertWardId) {
        return {
          ...ward,
          alert_count: wardAlertMap[alertWardId]?.length,
          accidents: wardAlertMap[alertWardId],
        };
      }
      return ward;
    });
    setWards(newWardAlerts);
    return newWardAlerts;
  };

  const getNotifications = () => {
    return notifications;
  };

  const audio = new Audio(alertSound);

  const addNotification = (notification) => {
    setNotifications((prevNotifications) => {
      const notificationExists = prevNotifications.some((n) => n.notificationId === notification.notificationId);

      if (notificationExists) {
        return prevNotifications;
      }
      if (notification.playsound) {
        if (document.hidden) {
          if (!audio.paused) {
            audio.pause();
            audio.currentTime = 0;
          }
          audio.play().catch((error) => {
            console.error('Failed to play audio:', error);
          });
        }
      }

      return [...prevNotifications, notification];
    });
  };

  const deleteNotification = (notificationId) => {
    setNotifications((prevNotifications) => prevNotifications.filter((notification) => {
      return notification.notificationId !== notificationId;
    }));
  };

  const reduceWardAlerts = (ward_id, alert_id) => {
    if (alert_id) {
      deleteNotification(alert_id);
    }
    setWards((prevWards) => {
      return prevWards.map((ward) => {
        if (ward.ward_id === ward_id) {
          return {
            ...ward,
            alert_count: ward.alert_count - 1,
          };
        }
        return ward;
      });
    });
  };

  const updateWards = useCallback((newWards) => {
    setWards(newWards);
  }, []);

  useEffect(() => {
    if (!wards && auth.user) {
      const roleId = get(auth.user, ['role', 'role_id']);
      if (roleId === 1) {
        getWards({ $expand: 'center_id/service_provider_id' })
          .then(({ data }) => {
            setWards(data);
          });
      } else {
        getUserServiceProviders({ user: auth.user.username}).then(({ data: userServiceProviders }) => {
          const serviceProviderIds = userServiceProviders.map((userServiceProvider) => userServiceProvider.service_provider);
          if (serviceProviderIds.length > 0) {
            return getWards({
              '$expand': 'center_id/service_provider_id',
              'center_id/service_provider_id/service_provider_id[in]': serviceProviderIds.join(),
            }).then(({ data }) => {
              setWards(data);
            });
          }
        });
      }
    } else if (!auth.user) {
      setWards(null);
    }
  }, [auth.user, wards]);

  return (
    <Provider value={{
      wards,
      notifications,
      setWards,
      updateWardAlerts,
      reduceWardAlerts,
      addNotification,
      deleteNotification,
      getNotifications,
    }}
    >
      {children}
    </Provider>
  );
};

WardsProvider.propTypes = {
  children: PropTypes.node,
};

export const withWards = (Component) => {
  return (props) => (
    <WardsConsumer>
      {({ wards, notifications, setWards, updateWardAlerts, reduceWardAlerts, addNotification, getNotifications, deleteNotification }) => (
        <Component
          wards={wards}
          notifications={notifications}
          setWards={setWards}
          getNotifications={getNotifications}
          updateWardAlerts={updateWardAlerts}
          reduceWardAlerts={reduceWardAlerts}
          addNotification={addNotification}
          deleteNotification={deleteNotification}
          {...props}
        />
      )}
    </WardsConsumer>
  );
};

export const useWards = () => useContext(WardsContext);

export default WardsContext;
