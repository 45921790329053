import React, { useCallback, useEffect, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import getStompClient from 'socket/stompClient';
import WardCard from './WardCard';
import ProblemWardCard from './ProblemWardCard';

const WardGrid = ({ withProblems, normal }) => {
  const [websocketClient, setWebsocketClient] = useState();
  const [radarData, setRadarData] = useState({});
  const addRadarMessageListener = useCallback((stompClient) => {
    if (stompClient && stompClient.connected) {
      stompClient.subscribe('/radar', (res) => {
        const resBody = JSON.parse(res.body);
        setRadarData(resBody);
      });
    }
  }, []);

  const connectRadarWebsocket = useCallback(() => {
    const stompClient = getStompClient('https://alpha-vision2.joyaethercloud.com/alpha-vision-in-websocket-server/ws');
    stompClient.onConnect = () => {
      setWebsocketClient(stompClient);
      if (stompClient.connected) {
        console.log('connected radar websocket');
        addRadarMessageListener(stompClient);
      }
    };
    stompClient.onStompError = (err) => {
      console.error('stompClient err', err);
    };
    stompClient.activate();
  }, [addRadarMessageListener]);

  useEffect(() => {
    if (!websocketClient) {
      connectRadarWebsocket();
    }
  }, [connectRadarWebsocket, websocketClient]);

  return (
    <MKBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={4}>
      <Grid container spacing={1.5}>
        {withProblems.map((ward, index) => {
          return (
            <Grid item xs={12} md={6} key={index}>
              <ProblemWardCard ward={ward} radarData={radarData} />
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={1} mt={2}>
        {normal.map((ward, index) => {
          return (
            <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
              <WardCard ward={ward} />
            </Grid>
          );
        })}
      </Grid>
    </MKBox>
  );
};

WardGrid.propTypes = {
  withProblems: PropTypes.array,
  normal: PropTypes.array,
};

export default WardGrid;
